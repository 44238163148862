<template>
  <div v-if="visible">
    <el-form ref="ruleFormRef" :model="ruleForm"  class="flex-1" status-icon>
      <div style="text-align: center;height: 60px;padding-top: 8px;font-weight: 600;font-size: 16px; color: #fe733d">正在制定材料标准，暂不能发起，请耐心等待！</div>
      <div style="text-align: center;height: 60px;padding-top: 8px;font-weight: 600;font-size: 16px;">当前机构的认证类型是{{organ.levelName}}</div>
      <div style="padding-left: 60px;">
        <div style="width: 100%;margin-top: 15px;border: 1px solid #666666"></div>
        <div style="margin-top: 15px;">请选择目前实际开展的再灌注治疗方法:<br>
          <span style="color: #fe733d">请根据贵单位实际开展的再灌注项目选择，未开展的请勿选择，尤其是PPCI要求较高，请谨慎选择，
          如选择后相关材料缺乏或条件不达标将影响评审得分，认证中更改选项将严重影响认证进度，
          强烈建议：咨询区域认证办公室老师后再确定选项。</span>
        </div>
        <el-form-item label="首选再灌注策略（单选）">
          <el-radio-group  v-model="fist" :disabled="!standardButton">
            <el-radio label="HOSPITAL">本院PPCI <span style="color: #fe733d;padding-left: 10px;">（本院急诊PPCI）</span></el-radio>
            <el-radio label="THROM">溶栓</el-radio>
            <el-radio label="TRANSPORT">转运PPCI  </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="次选再灌注策略（多选)">
          <el-checkbox-group v-model="measure" :disabled="!standardButton">
<!--            <el-checkbox label="HOSPITAL">本院PPCI<span style="color: #fe733d;padding-left: 10px;">（本院急诊PPCI）</span></el-checkbox>-->
            <el-checkbox label="THROM">溶栓</el-checkbox>
            <el-checkbox label="TRANSPORT">转运PPCI</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <div style="width: 100%;margin-top: 15px;border: 1px solid #666666"></div>
        <div class="center-float-center mt-20">
<!--          <el-button  type="primary" @click="submitForm('STANDARD')" size="large" :loading="loading" :disabled="standardButton">申请标准版胸痛中心</el-button>-->
<!--          <el-button  type="primary" @click="submitForm()" size="large" :loading="loading"  >发起认证申请</el-button>-->
          <el-button  type="primary" @click="submitForm()" size="large" :loading="loading" :disabled="isButton" >发起认证申请</el-button>
<!--          <el-button  type="primary" @click="submitForm()" size="large" :loading="loading" disabled >发起认证申请</el-button>-->

        </div>
      </div>

    </el-form>

  </div>
</template>

<script>
import {getOrganDetail, postCertifiedApply} from "@/api";
const getNowDate = () => {
  var date = new Date();
  var sign2 = ":";
  var year = date.getFullYear() // 年
  var month = date.getMonth() + 1; // 月
  var day = date.getDate(); // 日
  var hour = date.getHours(); // 时
  var minutes = date.getMinutes(); // 分
  var seconds = date.getSeconds() //秒
  // 给一位数的数据前面加 “0”
  if (month >= 1 && month <= 9) {
    month = "0" + month;
  }
  if (day >= 0 && day <= 9) {
    day = "0" + day;
  }
  if (hour >= 0 && hour <= 9) {
    hour = "0" + hour;
  }
  if (minutes >= 0 && minutes <= 9) {
    minutes = "0" + minutes;
  }
  if (seconds >= 0 && seconds <= 9) {
    seconds = "0" + seconds;
  }
  // return year + "-" + month + "-" + day + " " + hour + sign2 + minutes + sign2 + seconds;
  return year + "-" + month + "-" + day
}
export default {
  name: "TypeIndex",
  data(){
    return{
      basicUrl: this.$basicUrl,
      loading:false,
      visible:true,
      organ:'',
      data:'',
      organId:'',
      isButton:true,
      standardButton:true,
      standard:[{code:'101',meet:'',path:'',type:null,typeName:'上一年度PCI总量大于等于200例',name:'',time:''},{code:'102',meet:'',path:'',type:null,typeName:'上一年度急诊PCI量大于等于50例？',name:'',time:''},{code:'103',meet:'',path:'',type:null,typeName:'至少有2名接受过规范培训，具备急诊PCI能力的副高级职称的心血管专科医师，且每年PCI量不少于75例\n',name:'',time:''}],
      basicButton:true,
      fist:'',
      basic:[{code:'104',meet:'',path:'',type:null,typeName:'年接诊心肌梗死患者大于等于30例：',name:'',time:''},],
      ruleForm:{},
      radio:'',
      measure:[],
      fileList:[],
      upload: {
        url1: '',
        url2:'',
        url3:'',
        url4:'',
      },
    }
  },
  mounted() {
    if (JSON.parse(localStorage.getItem('cpis-web_userInfo'))) {
      this.organId = JSON.parse(localStorage.getItem('cpis-web_userInfo')).id
    }
    this.reqOrganDetail();
    this.reqDraftsStatus();


  },
  methods:{
    // 获取机构信息
    async reqOrganDetail() {
      const self=this;
      const res = await getOrganDetail(this.organId)
      self.organ = res.biz
      const date1=new Date(getNowDate());
      const date2=new Date('2024-6-24');
      console.log(date1.getTime()>date2.getTime())
      if((null == this.organ.authProfile ||  this.organ.authProfile == undefined || this.organ.authProfile == '') || ((null != this.organ.authProfile &&  this.organ.authProfile != undefined && this.organ.authProfile != '') && this.organ.authProfile.status=="PASS" && (date1.getTime()>date2.getTime()))){
        this.isButton=false;
      }
    },
    //草稿箱状态判断
    async reqDraftsStatus() {
      const self=this;
      this.$axios.post('/api/customer/auth/'+self.organId+'/draft/state')
          .then((res) => {
            self.loading = false;
            if(null != res && null != res.msg && res.msg.flag === 0){
              self.isButton=res.biz;

              // self.$message.success({message: res.msg.message});
            }else{
              self.error = {loading: true, message: res.msg.message};
            }
          }).catch(() => {
        self.loading = false;
      });
    },
    async reqSubmit() {
     const self = this;
      this.error ={loading: false, message: ''};
      this.$refs.ruleFormRef.validate((valid) =>{
        if (valid) {
          this.loading = true;
          let params
          params = {id:self.organId,fistMeasure:self.fist,measure:self.measure};

          this.$axios.post('/api/customer/auth/draft/apply', params)
              .then((res) => {
                self.loading = false;
                if(null != res && null != res.msg && res.msg.flag === 0){
                  this.$router.push('/auth/material')
                  self.$message.success({message: res.msg.message});
                }else{
                  self.error = {loading: true, message: res.msg.message};
                }
              }).catch(() => {
            self.loading = false;
          });
        }
      });
    },
    //草稿箱申请
    submitForm() {
      this.$confirm('确定发起认证申请吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.reqSubmit()
      }).catch(() => {
      });
    },
    openFile:function (url){
      window.open(url);
    }
  },

}
</script>

<style scoped>
.el-form-item--small.el-form-item {
  margin-bottom: 0px;
}
</style>